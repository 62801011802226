@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #252525;
    overflow: hidden;
    z-index: -1;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
}




.background li:nth-child(0) {
    left: 33%;
    width: 129px;
    height: 129px;
    bottom: -129px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 74%;
    width: 199px;
    height: 199px;
    bottom: -199px;
    animation-delay: 3s;
}
.background li:nth-child(2) {
    left: 74%;
    width: 122px;
    height: 122px;
    bottom: -122px;
    animation-delay: 4s;
}
.background li:nth-child(3) {
    left: 43%;
    width: 134px;
    height: 134px;
    bottom: -134px;
    animation-delay: 1s;
}
.background li:nth-child(4) {
    left: 63%;
    width: 124px;
    height: 124px;
    bottom: -124px;
    animation-delay: 17s;
}
.background li:nth-child(5) {
    left: 29%;
    width: 147px;
    height: 147px;
    bottom: -147px;
    animation-delay: 10s;
}
.background li:nth-child(6) {
    left: 25%;
    width: 133px;
    height: 133px;
    bottom: -133px;
    animation-delay: 17s;
}
.background li:nth-child(7) {
    left: 79%;
    width: 174px;
    height: 174px;
    bottom: -174px;
    animation-delay: 13s;
}
.background li:nth-child(8) {
    left: 25%;
    width: 182px;
    height: 182px;
    bottom: -182px;
    animation-delay: 36s;
}
.background li:nth-child(9) {
    left: 0%;
    width: 166px;
    height: 166px;
    bottom: -166px;
    animation-delay: 36s;
}